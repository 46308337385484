class FormErrors {

    constructor() {
        this.errors = '';
    }

    clearAll() {
        this.errors = '';
    }

    get( field ) {
        if( this.hasError(field) ) {
            return this.errors[field][0];
        }
    }

    hasError( field ) {
        return this.errors[field]
    }

    clear( field ) {
        delete this.errors[field]
    }

    record( errors ) {
        this.errors = errors
    }

}
export default FormErrors;