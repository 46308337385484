
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('alpinejs');
import Vue from 'vue';

// Vue.component( 'registry-list', require( './components/RegistryList' ) );
// Vue.component( 'upload-product-image', require( './components/UploadProductImage' ) );
// Vue.component( 'document-uploader', require( './components/DocumentUploader' ) );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const app = new Vue({
    el: '#app'
});
if (document.querySelector('.menu-toggle')) {
    document.querySelector('.menu-toggle').addEventListener('click', ev => {
        ev.preventDefault();

        if (ev.currentTarget.classList.contains('active')) {
            document.querySelector('.main-sidebar').classList.remove('open');
            document.querySelector('.aside-overlay').classList.remove('show');
            document.querySelector('body').classList.remove('overflow-hidden');
            ev.currentTarget.classList.remove('active');

        } else {
            document.querySelector('.main-sidebar').classList.add('open');
            document.querySelector('.aside-overlay').classList.add('show');
            document.querySelector('body').classList.add('overflow-hidden');
            ev.currentTarget.classList.add('active');
        }
    });
}

if (document.querySelector('.menu-close')) {
    document.querySelector('.menu-close').addEventListener('click', ev => {
        ev.preventDefault();
        document.querySelector('.main-sidebar').classList.remove('open');
        document.querySelector('.aside-overlay').classList.remove('show');
        document.querySelector('body').classList.remove('overflow-hidden');
        document.querySelector('.menu-toggle').classList.remove('active');

    });
}