<template>
    <div class="form-group">
        <div class="form-check">
            <input type="hidden" :name="name" :id="name" v-model="value">
            <label class="form-check-label" @click.prevent="toggle" :for="name">
                <span class="icon"><i class="far" :class="{ 'fa-square': !checked, 'fa-check-square': checked }"></i></span>
                <slot></slot>
            </label>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['name', 'old'],
        data() {
            return {
                checked: false,
                value: "0"
            }
        },
        created() {
            if (typeof this.old === 'boolean') {
                this.checked = this.old
            }
        },
        methods: {
            toggle() {
                this.checked = !this.checked
                this.$emit('input', this.checked)
            }
        },
        watch: {
            checked( value ) {
                this.value = (value) ? "1" : "0"
            }
        }
    }
</script>