<template>
    <div class="image-uploader">
        <div class="progress" v-if="progress > 0 && progress < 100">
            <div class="progress-bar" :style="'width: '+progress+'%;'">{{ progress }}%</div>
        </div>
        <div class="input-group mb-2" v-if="caption">
            <div class="input-group-prepend"><label for="caption" class="input-group-text">Nome</label></div>
            <input type="text" id="caption" v-model="input" class="form-control">
        </div>
        <small class="form-text text-danger" v-if="errors.hasError('caption')" v-text="errors.get('caption')"></small>
        <div class="input-group">
            <div class="custom-file">
                <input @change="changeFileName" type="file" id="upload" class="custom-file-input">
                <label class="custom-file-label" for="upload" v-text="filename"></label>
            </div>
            <a href="#" @click.prevent="uploadFile" class="input-group-append">
                <span class="input-group-text">Carica</span>
            </a>
        </div>
        <small class="form-text text-danger" v-if="errors.hasError('file')" v-text="errors.get('file')"></small>
    </div>
</template>
<script>
import FormErrors from './../FormErrors'

export default {
    props: {
        registryId: {
            required: true,
        },
        productId: {
            required: true,
        },
        caption: {
            required: false,
            type: Boolean,
            default: false
        },
        type: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            filename: 'Clicca qui per selezionare un file',
            file: null,
            media: null,
            input: '',
            progress: 0,
            errors: new FormErrors()
        }
    },
    methods: {
        changeFileName(e) {
            this.filename = e.currentTarget.files[0].name;
            this.file = e.currentTarget.files[0];
        },
        uploadFile(e) {
            let formData = new FormData();
            if (this.caption) formData.append('caption', this.input);

            formData.append('registry_id', this.registryId);
            formData.append('product_id', this.productId);
            formData.append('type', this.type);
            formData.append('file', this.file);

            axios.post('/media/upload', formData, this.axiosConfig())
                .then(response => {
                    this.image = response.data;
                    this.$emit('uploaded', response.data);
                    this.input = '';
                    this.progress = 0;
                    this.errors.clearAll();
                })
                .catch(error => {
                    this.errors.record(error.response.data.errors);
                })
        },
        axiosConfig() {
            let vue = this;
            return {
                onUploadProgress(progressEvent) {
                    vue.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                }
            }
        }
    }
}
</script>